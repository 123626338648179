import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import { SupportEmail } from '../components/email/email';

const Support = ({ data }) => (
  <Layout>
    <Head pageTitle={data.supportJson.title} />
    <Box fill="horizontal">
      <Box width="large" alignSelf="center">
        <SectionTitle title="Download Support Manuals" />
        {
          <Box width="100%" alignSelf="center" height="700px">
            {
              <SupportEmail></SupportEmail>
              /* <iframe
            title="OtoSim Form"
            src="https://www2.otosim.com/l/204492/2019-02-25/8ljd7"
            width="100%"
            height="100%"
            type="text/html"
            frameBorder="0"
            style={{ border: 0 }}
          /> */
            }
          </Box>
        }
      </Box>
    </Box>
  </Layout>
);

Support.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Support;

export const query = graphql`
  query SupportFormQuery {
    supportJson {
      title
    }
  }
`;
